<template>
  <div id="app" class="full-screen-size">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    window.addEventListener(
      'message',
      (event) => {
        if (
          event &&
          event.data &&
          String(event.data).indexOf('selectionText') > -1
        ) {
          this.$router.replace({
            name: 'Home',
            query: {
              search: JSON.parse(event.data).selectionText,
            },
          })
        }
      },
      false
    )
  },
}
</script>

<style lang="less">
@import '~ant-design-vue/dist/antd.less';

@import 'styles/color';
@import 'styles/behaviour';
@import 'styles/border';
@import 'styles/position';
@import 'styles/size';

@import 'styles/theme';

#app {
  font-family: @font-family;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
