import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/layout'),
    children: [
      {
        name: 'Home',
        path: '',
        component: () => import('@/pages/main'),
      },
      {
        name: 'Detail',
        path: '/detail',
        component: () => import('@/pages/detail'),
      },
      {
        name: 'baikeEdit',
        path: '/baikeEdit',
        component: () => import('@/pages/baike/edit'),
      }
    ],
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
